import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AsciinemaEmbed = makeShortcode("AsciinemaEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Writenull is a CLI program for writing null or random data to a file.`}</p>
    <h2>{`Demo`}</h2>
    <AsciinemaEmbed id="324619" width="700px" height="300px" mdxType="AsciinemaEmbed" />
    <h2>{`Source`}</h2>
    <p>{`Source code for `}<inlineCode parentName="p">{`writenull`}</inlineCode>{` is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/writenull"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      